import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const App = () => {

  useEffect(() => {
    // Lock scrolling
    document.body.style.overflow = 'hidden';

    // Clean up to re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>KrashidBuilt</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-br from-gray-800 via-gray-900 to-gray-700 text-gray-100 text-center">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
          className="text-4xl font-bold text-gray-200"
        >
          KrashidBuilt
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 1 }}
          className="mt-4 text-lg text-gray-400"
        >
          Building the extraordinary.
        </motion.p>
        <a
          href="mailto:hello@krashidbuilt.com"
          className="mt-6 text-lg text-gray-300 hover:text-gray-500"
        >
          hello@krashidbuilt.com
        </a>
      </div>
    </>
  );
};

export default App;